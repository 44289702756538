*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: theme("fontFamily.montserrat");
    scroll-behavior: smooth;
}

body {
    @apply tw-overflow-x-hidden;
}

main {
    @apply tw-transition-transform tw-duration-500 tw-ease-in-out;

    &.move {
        @apply tw-transform -tw-translate-x-1/3;
    }
}
