.Accordion {
    $icon-animation-duration: 200ms;

    .toggle-collapse {
        @apply tw-border-t tw-border-b tw-border-t-transparent tw-duration-1000 tw-ease-in-out;

        transition-property: border-color;

        .minus {
            @apply tw-hidden;
        }

        .plus {
            @apply tw-transform;

            animation: spin $icon-animation-duration ease-out;
        }

        &.active {
            .plus {
                @apply tw-hidden;
            }

            .minus {
                @apply tw-block tw-transform;

                animation: spin $icon-animation-duration ease-out;
            }
        }
    }

    .collapse {
        @apply tw-duration-1000 tw-ease-in-out tw-overflow-hidden;

        transition-property: max-height;

        &.open {
            & + .toggle-collapse {
                @apply tw-border-t-gray-100;
            }
        }

        a {
            @apply tw-text-brand-500;
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(-90deg);
    }
    to {
        transform: rotate(-180deg);
    }
}
