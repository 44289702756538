.areabrick {
    ul {
        li {
            &::before {
                @apply tw-inline-block tw-mr-1;
                content: '\2022';
            }
        }
    }

    ol {
        list-style: inside decimal-leading-zero;
    }
}