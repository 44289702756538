@import "~dropzone/dist/dropzone.css";

.Form {
    form {
        &.is-loading {
            * {
                @apply tw-pointer-events-none;
            }

            button {
                @apply tw-opacity-50;
            }
        }
    }

    .dropzone {
        @apply tw-border-gray-100 tw-mb-4 tw-rounded-none focus:tw-outline-brand-500;
    }

    :not(.editmode) & {
        .person {
            @apply md:tw-sticky tw-top-20 tw-h-fit;
        }
    }

    .dropzone {
        .dz-error-mark {
            @apply tw-bg-brand-500;
        }
    }

    label {
        &.required {
            &:after {
                content: "*";
            }
        }
    }
}
