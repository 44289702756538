.Cards {
    .cards {
        @apply lg:tw--mx-3;

        & > * {
            @apply lg:tw-px-6 lg:tw-py-4 tw-relative;
        }

        @screen xl {
            & > *:not(:nth-of-type(1)) {
                &::before {
                    content: '';
                    @apply lg:tw-absolute lg:tw-top-3 lg:tw-bottom-3 lg:tw-left-0 lg:tw-border-l lg:tw-border-gray-100;
                }
            }

            & > *:nth-of-type(1) {
                @apply lg:tw-pl-3;
            }

            & > *:nth-last-of-type(1) {
                @apply lg:tw-pr-3;
            }

            &.show-borders {
                & > *:nth-of-type(4n+1) {
                    @apply lg:tw-pl-3;

                    &::before {
                        content: none;
                    }
                }

                & > *:nth-of-type(n+5) {
                    &::after {
                        content: '';
                        @apply lg:tw-absolute lg:tw-top-0 lg:tw-left-3 lg:tw-right-3 lg:tw-border-t lg:tw-border-gray-100;
                    }

                    @apply lg:tw-pt-3;
                }
            }
        }
    }
}
