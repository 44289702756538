.SubPageHero {
    .image-container {
        margin-right: calc(-1 * ((100vw - 100%) / 2));
    }

    & > .tw-container::after {
        content: "";

        @apply tw-absolute tw-top-0 tw-right-0 sm:tw-right-psm lg:tw-right-plg xl:tw-right-pxl 2xl:tw-right-p2xl tw-bottom-24 tw-bg-brand-700 -tw-z-10;

        left: calc(-1 * ((100vw - 100%) / 2));
    }

    .btn {
        @apply tw-relative tw-z-10 tw-transition-colors tw-ease-in-out tw-duration-300;

        &::after {
            content: "";

            @apply tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white -tw-z-10 tw-transform tw-scale-x-0 tw-origin-right tw-transition-transform tw-duration-300 tw-ease-in-out;
        }

        &:hover {
            @apply tw-text-brand-500;

            &::after {
                @apply tw-scale-100 tw-origin-left;
            }
        }
    }
}
