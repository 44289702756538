.CardSlider {
    .swiper-pagination,
    .pagination {
        .swiper-pagination-bullet {
            @apply tw-w-4 tw-h-1 tw-bg-gray-100 tw-inline-block;
        }

        .item.active,
        .swiper-pagination-bullet-active
        {
            @apply tw-bg-brand-500;
        }
    }

    .editmode & {
        .pimcore_editable_image {
            @apply tw-overflow-hidden tw-aspect-video;
            width: 100% !important;
        }

        .pimcore_editable_image img {
            @apply tw-object-cover tw-w-full;
        }
    }
}
