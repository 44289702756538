.Header {
    #toggle-menu {
        &.open {
            #open {
                @apply tw-hidden;
            }
        }

        &:not(.open) {
            #close {
                @apply tw-hidden;
            }
        }
    }

    #toggle-languages{
        svg{
            @apply tw-transition-transform tw-duration-500 tw-ease-in-out;
        }

        & + ul{
            @apply tw-opacity-0 tw-pointer-events-none tw-translate-y-0 tw-transition-all tw-duration-500 tw-ease-in-out;

            transition-property: opacity, transform;
        }

        &.open{
            svg{
                @apply -tw-rotate-180;
            }

            & + ul{
                @apply tw-opacity-100 tw-pointer-events-auto tw-translate-y-2;
            }
        }
    }

    & > .tw-container {
        @apply tw-duration-300 tw-transition tw-ease-in-out;

        transition-property: height;

        @media (max-width: theme("screensMax.md")) {
            .logo {
                @apply tw-duration-300 tw-transition tw-ease-in-out;
                transition-property: height;
            }
        }

        .full-logo {
            animation: fadeIn 500ms ease-in-out;
        }
    }

    &.scroll {
        & > .tw-container {
            @apply tw-h-16 lg:tw-h-20;

            .logo {
                @apply tw-h-5 md:tw-h-7 lg:tw-h-10;

                .full-logo {
                    @apply tw-hidden;
                }

                .small-logo {
                    @apply tw-block;

                    animation: fadeIn 500ms ease-in-out;
                }
            }
        }
    }

    nav {
        .main-links {
            li:nth-last-of-type(1) {
                @apply tw-border-b;
            }
        }

        @media (max-width: theme("screensMax.lg")) {
            @apply tw-pointer-events-none tw-duration-500 tw-ease-in-out tw-left-0 tw-overflow-y-auto;

            transition-property: clip-path;
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

            &.open {
                @apply tw-pointer-events-auto;

                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }

        @media (min-width: theme("screens.lg")) {
            @apply tw-transition-transform tw-transform tw-duration-500 tw-ease-in-out tw-translate-x-full;

            height: calc(100vh - 100%);

            &.open {
                @apply tw-translate-x-0;
            }
        }
    }
}

@keyframes fadeIn {
    from {
        @apply tw-opacity-0;
    }
    to {
        @apply tw-opacity-100;
    }
}
