.MapDetails {
    @media (min-width: theme("screens.sm")) and (max-width: theme("screensMax.xl")) {
        .map {
            grid-row: 1 / 2;
            grid-column: 2 / 3;
        }

        .details {
            grid-row: 2 / 3;
            grid-column: 1 / -1;
        }
    }

    @media (min-width: theme("screens.xl")) {
        grid-template-columns: 400px 1fr 290px;
    }

    .map {
        .pointer {
            @apply tw-rounded-full tw-absolute -tw-translate-x-1/2 -tw-translate-y-1/2 tw-bg-brand-500 tw-w-1.5 tw-h-1.5 tw-transition-transform tw-duration-500 tw-ease-in-out tw-origin-center;

            &.active {
                --tw-scale-x: 2;
                --tw-scale-y: 2;
            }
        }

        .circle {
            @apply tw-rounded-full tw-absolute -tw-translate-x-1/2 -tw-translate-y-1/2 tw-border tw-border-brand-500 tw-w-1.5 tw-h-1.5 tw-origin-center;

            @keyframes grow {
                to {
                    @apply tw-w-16 tw-h-16 tw-opacity-0;
                }
            }

            @keyframes growActive {
                to {
                    @apply tw-w-40 tw-h-40 tw-opacity-0;
                }
            }
        }
    }

    .details {
        @media (max-width: theme("screens.sm")) {
            & > *:not(:nth-last-child(-n + 2)) {
                @apply tw-border-b tw-border-b-gray-100;
            }
        }

        @media (min-width: theme("screens.xl")) {
            & > *:not(:nth-last-child(1)) {
                @apply tw-border-b tw-border-b-gray-100;
            }

            & > *:not(:nth-of-type(1)) {
                @apply tw-pt-6;
            }
        }
    }
}
