h2 {
    @apply tw-text-2xl xl:tw-text-3xl tw-font-bold;
}

a {
    &:not(.no-hover) {
        @apply tw-relative tw-w-fit;

        &::after {
            content: "";

            @apply tw-absolute tw-left-0 tw-right-0 -tw-bottom-1 tw-h-0.5 tw-bg-current tw-transform tw-scale-x-0 tw-origin-right tw-transition-transform tw-duration-300 tw-ease-in-out;
        }

        &:hover,
        &.active {
            &::after {
                @apply tw-scale-x-100 tw-origin-left;
            }
        }

        &:not(.no-color-hover) {
            @apply tw-transition-colors tw-duration-300 tw-ease-in-out;

            &:hover {
                @apply tw-text-brand-500;
            }
        }
    }
}
