.MainHero {
    .image-container {
        margin-right: calc(-1 * ((100vw - 100%) / 2));
    }

    & > .tw-container::after {
        content: "";
        @apply tw-absolute tw-top-0 tw-right-0 sm:tw-right-psm lg:tw-right-plg xl:tw-right-pxl 2xl:tw-right-p2xl tw-bottom-24 md:tw-bottom-36 lg:tw-bottom-44 xl:tw-bottom-56 tw-bg-brand-700 -tw-z-10;
        left: calc(-1 * ((100vw - 100%) / 2));
    }
}
