@tailwind base;
@tailwind components;
@tailwind utilities;

@import "import-fonts";
@import "svg";
@import "typgraphy";
@import "base";
@import "components";
@import "lists";
