.Breadcrumb {
    ul {
        li:nth-of-type(1) {
            @apply tw-text-brand-500;
        }

        li:not(:nth-last-of-type(1)) {
            &::after {
                content: "";

                @apply tw-border-gray-900 tw-border-r-2 tw-border-t-2 tw-w-1.5 tw-h-1.5 tw-inline-block tw-transform tw-rotate-45 -tw-translate-y-0.5;
            }
        }
    }
}
