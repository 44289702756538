.Slider {
    .pagination {
        & > *.active {
            @apply tw-bg-brand-500;
        }
    }

    .swiper-slide:not(.swiper-slide-active){
        height: 0 !important;
    }
}
