.MapLocations {
    @media (min-width: theme("screens.sm")) and (max-width: theme("screens.xl")) {
        .map {
            grid-row: 2 / 3;
        }
    }

    @media (min-width: theme("screens.xl")) {
        grid-template-columns: 400px 1fr 290px;
    }

    .map {
        svg {
            path {
                @apply tw-transition-colors tw-duration-300 tw-ease-in-out;

                &.active {
                    @apply tw-fill-brand-500;
                }
            }
        }

        .pointer {
            @apply tw-transition-all tw-duration-300 tw-ease-in-out;

            border-radius: 50%;
            transition-property: left, bottom;

            &::after {
                content: "";
                @apply tw-absolute tw-h-2 tw-w-2 tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-rounded-full tw-bg-brand-500;
            }
        }
    }

    .accordion {
        .accordion-item {
            &:not(:nth-of-type(1)) {
                @apply tw-mt-4;
            }

            .accordion-button {
                @apply tw-transition-colors tw-duration-300 tw-ease-in-out;

                &.active {
                    @apply tw-text-brand-500;
                }
            }

            .accordion-content {
                @apply tw-overflow-hidden tw-transition-all tw-duration-300 tw-ease-in-out tw-h-0;
                transition-property: height;
            }
        }
    }
}
